import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import PageTitle from '../components/PageTitle'
import Container from '../components/Container'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import ContainerHero from '../components/ContainerHero'
import Subheadline from '../components/Subheadline'

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  h1 {
    padding: 30px 15px;
    border-bottom: 2px solid #000;
    @media (min-width: ${props => props.theme.responsive.medium}) {
      padding: 30px;
    }
  }
`

const DankePage = () => {
  return (
  <Layout>
    <SEO title="Danke!" description="Danke für Ihre Anfrage!" />
    <Container>
      <ContainerHero>
        <ContentContainer>
          <PageTitle>Termin eingetragen!</PageTitle>
          <Subheadline>
            Danke für Ihre Terminanfrage.
          <br />
            Wir freuen uns auf das Gespräch!{' '}
            <br />
            <br />→ Zur <Link to="/">Startseite</Link> zurückkehren.
          </Subheadline>
        </ContentContainer>
      </ContainerHero>
    </Container>
  </Layout>
  )
}

export default DankePage
